/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { baseInput } from '../../elements/input/styles';
import { routes } from '../../shared/constants';

const StyledContainer = styled.div`
  max-width: 900px;
  margin: 30px auto;
  padding: 0px 40px;

  @media (max-width: ${props => props.theme.components.phoneWidthMax}) {
    padding: 0px 30px;
  } 
`;

const FieldContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: ${props => props.theme.size.large};
  line-height: 24px;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  ${baseInput}
`;

const SuccessContainer = styled.div`
  color: ${props => props.theme.colours.green};
  margin: 30px 0px;
`;

const ErrorContainer = styled.div`
  color: ${props => props.theme.colours.red};
  margin: 30px 0px;
`;

export default function LoginForm({
  data
}) {
  const {
    hashId,
    forgotHeading,
    instructions,
    submitButton,
    successMessage,
    errorMessage
  } = data;

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const emailRegex = `[a-zA-Z0-9!#$%&-_'*+\/=?^_{|}~.]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$`;

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(document.getElementById(`forgotpasswordform-${hashId}`));

    fetch(`${routes.global.urls.domains.root}mobile/submitforgotpassword/?__amp_source_origin=https%3A%2F%2F${window.document.domain}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'amp-same-origin': 'true'
      },
      body: formData
    })
      .then(response => response.json())
      .then(() => {
        setSuccess(true);
        setError(false);
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
      });
  };

  return (
    <div id={hashId}>
      <StyledContainer>
        <div className="component-content">
          <h1>{forgotHeading}</h1>
          <p>{instructions}</p>
          <form id={`forgotpasswordform-${hashId}`} method="post" target="_top" onSubmit={handleSubmit}>
            <FieldContainer>
              <StyledLabel htmlFor="email">Email*</StyledLabel>
              <StyledInput id="email" name="email" type="email" autocomplete="off" pattern={emailRegex} required />
            </FieldContainer>

            {/* Button */}
            <div className="buttonWrap">
              <input type="submit" className="button btn-green" value={submitButton} />
            </div>

            {/* Success */}
            {success && <SuccessContainer>{successMessage}</SuccessContainer>}

            {/* Error */}
            {error && <ErrorContainer>{errorMessage}</ErrorContainer>}
          </form>

        </div>
      </StyledContainer>
    </div>

  );
}

LoginForm.propTypes = {
  data: PropTypes.shape({
    hashId: PropTypes.string,
    forgotHeading: PropTypes.string,
    instructions: PropTypes.string,
    submitButton: PropTypes.string,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string
  })
};
